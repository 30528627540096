import { Component } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { AuthService } from './services/auth.service';
import { environment } from 'src/environments/environment';
import { TokenModel } from './models/token.model';
import { UserModel } from './models/user.model';
import { AccountService } from './services/account.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private authService: AuthService,
    private accountService: AccountService
  ) {
    this.initialize()
  }

  async initialize(){

    var token = await Preferences.get({key:'token'});

    if(token.value){
      this.authService.setTokenObserver(new TokenModel().deserialize(JSON.parse(atob(token.value))))
      this.checkToken()
    }

    var userDetail = await Preferences.get({key:'userDetail'});

    if(userDetail.value){
      this.authService.setUserObserver(new UserModel().deserialize(JSON.parse(atob(userDetail.value))))
    }

  }

  checkToken(){
    this.accountService.getUserDetail().subscribe(
      {
        next:async (resData) => {
        await Preferences.set({key:'userDetail', value:btoa(JSON.stringify(resData.data))})
        var user:UserModel = new UserModel().deserialize(resData.data);
        this.authService.setUserObserver(user)

        },
        error: (err) => {
          if(err.status == 401){
            this.authService.logoutNOREDIRECT()
          }
        },
      }
    );
  }

}
