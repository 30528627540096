import { DomSanitizer } from "@angular/platform-browser";
import { Deserializable } from "./deserializable.model";

export class TokenModel implements Deserializable{

	private access_token:string = ''
	private token_type:string = ''
	private expires_in:string = ''

	deserialize(input: any) {
		Object.assign(this, input);
		return this;
	}

	getToken(){
		return this.access_token
	}

}
