import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { take, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Subject, BehaviorSubject } from 'rxjs';
import { LanguageService } from './language.service';


@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private sbj = new Subject<any>();
  private sbjShop = new BehaviorSubject(0);
  manageAddress = this.sbjShop.asObservable();
  language:any;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private languageService: LanguageService
  )
  {
    this.language = this.languageService.language();
  }

  //new

  getUserDetail(){

    var token = this.authService.getToken();

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${token}`
      })
    };
    return this.http.get<any>(
      `${environment.baseUrl}/api/users`, httpOptions
    );

  }

  myAnswerCheck(gameID:number, phaseID:number){
    var token = this.authService.getToken();

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${token}`
      })
    };

    return this.http.get<any>(
      `${environment.baseUrl}/api/events/${environment.eventID}/games/${gameID}/phases/${phaseID}/my-answer`, httpOptions
    );
  }

  SubmitQuiz(gameID:number, pahaseID:number, optionID:any){

    var token = this.authService.getToken();

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${token}`
      })
    };

    let body = new FormData();
    // body.append("email", formData.email);
    body.append("phase_id", pahaseID.toString());
    body.append("option", optionID.toString());

    return this.http.post<any>(
      `${environment.baseUrl}/api/events/${environment.eventID}/games/${gameID}/phases`,body, httpOptions
    );

  }

  SubmitVote(gameID:number, pahaseID:number, optionID:any, voteID:any){

    var token = this.authService.getToken();

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${token}`
      })
    };

    let body = new FormData();
    body.append("option", optionID.toString());
    body.append("vote_id", voteID.toString());

    return this.http.post<any>(
      `${environment.baseUrl}/api/events/${environment.eventID}/games/${gameID}/phases/${pahaseID}/vote`,body, httpOptions
    );

  }

  VoteResult(gameID:number, pahaseID:number, voteID:number){

    var token = this.authService.getToken();

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${token}`
      })
    };

    return this.http.get<any>(
      `${environment.baseUrl}/api/events/${environment.eventID}/games/${gameID}/phases/${pahaseID}/vote/${voteID}/result`,httpOptions
    );

  }

  myAnswerVote(gameID:number, pahaseID:number, voteID:number){

    var token = this.authService.getToken();

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${token}`
      })
    };

    return this.http.get<any>(
      `${environment.baseUrl}/api/events/${environment.eventID}/games/${gameID}/phases/${pahaseID}/vote/${voteID}/my-answer`,httpOptions
    );

  }

}
