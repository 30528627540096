import { Deserializable } from "./deserializable.model";

export interface MyEvent {
	event_id: number,
	event_name: string,
	event_image: string,
	event_location: string,
	event_start_date: string,
	event_end_date: string
}



export class UserModel implements Deserializable {

  private id:number = 0
  private group:string = ''
  private name:string = ''
  private branch:string = ''
  private email:string = ''

  public constructor() {}

	deserialize(input: any) {
		Object.assign(this, input);

		return this;
	}

  getMemberID(){
    return this.id
  }

	getInitial(){

		if(this.name){
			let member_name = this.name.split(" ");
			if (member_name.length > 1) {

				return member_name[0].charAt(0).toUpperCase() + member_name[1].charAt(0).toUpperCase();

			} else {

				return member_name[0].charAt(0).toUpperCase();

			}
		}else{
      return ''
    }

	}

  getGroupNumber(){
    return this.group;
  }

  getMemberFullName(){
    return this.name;
  }

  getEmail(){
    return this.email
  }

  getBranch(){
    return this.branch
  }

}
