import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/Guard/auth.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  // {
  //   path: '',
  //   redirectTo: '/screen',
  //   pathMatch: 'full'
  // },
  {
    path: '',
    loadChildren: () => import('./Pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'semifinal-game',
    loadChildren: () => import('./Pages/quiz/first-stage/first-stage.module').then( m => m.FirstStagePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'semifinal-screen',
    loadChildren: () => import('./Pages/quiz/screen/screen.module').then( m => m.ScreenPageModule)
  },
  {
    // path: 'final-user',
    path: 'game',
    loadChildren: () => import('./Pages/final/user/user.module').then( m => m.UserPageModule),
    canActivate: [AuthGuard]
  },
  {
    // path: 'final-screen',
    path: 'screen',
    loadChildren: () => import('./Pages/final/screen/screen.module').then( m => m.ScreenPageModule)
  },
  {
    path: 'first-stage',
    loadChildren: () => import('./Pages/quiz/first-stage/first-stage.module').then( m => m.FirstStagePageModule)
  },
  {
    path: 'second-stage',
    loadChildren: () => import('./Pages/quiz/second-stage/second-stage.module').then( m => m.SecondStagePageModule)
  },
  {
    path: 'stream-first-stage',
    loadChildren: () => import('./Pages/quiz/stream-first-stage/stream-first-stage.module').then( m => m.StreamFirstStagePageModule)
  },
  {
    path: 'third-stage',
    loadChildren: () => import('./Pages/quiz/third-stage/third-stage.module').then( m => m.ThirdStagePageModule)
  },
  {
    path: 'third-stage-question',
    loadChildren: () => import('./Pages/quiz/third-stage-question/third-stage-question.module').then( m => m.ThirdStageQuestionPageModule)
  },
  {
    path: 'leaderboard-personal',
    loadChildren: () => import('./Pages/quiz/leaderboard-personal/leaderboard-personal.module').then( m => m.LeaderboardPersonalPageModule)
  },
  {
    path: 'leaderboard-unit',
    loadChildren: () => import('./Pages/quiz/leaderboard-unit/leaderboard-unit.module').then( m => m.LeaderboardUnitPageModule)
  },
  {
    path: 'leaderboard-stage',
    loadChildren: () => import('./Pages/quiz/leaderboard-stage/leaderboard-stage.module').then( m => m.LeaderboardStagePageModule)
  },
  {
    path: 'stream-screen',
    loadChildren: () => import('./Pages/quiz/stream-screen/stream-screen.module').then( m => m.StreamScreenPageModule)
  },
  {
    path: 'leaderboard-top3',
    loadChildren: () => import('./Pages/quiz/leaderboard-top3/leaderboard-top3.module').then( m => m.LeaderboardTop3PageModule)
  },
  {
    path: 'dual-login',
    loadChildren: () => import('./Pages/dual-login/dual-login.module').then( m => m.DualLoginPageModule)
  },
  {
    path: 'dual-login-prompt/:code',
    loadChildren: () => import('./Pages/dual-login-prompt/dual-login-prompt.module').then( m => m.DualLoginPromptPageModule)
  },
  {
    path: 'leaderboard',
    loadChildren: () => import('./Pages/final/leaderboard/leaderboard.module').then( m => m.LeaderboardPageModule)
  },
  {
    path: 'team-point/:name',
    loadChildren: () => import('./Pages/final/team-point/team-point.module').then( m => m.TeamPointPageModule)
  },




];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
