// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  eventID:1,
  baseUrl: "https://app-quiz.diginesiaku.com",
  captchaKey: "6LcHv2snAAAAAD-nNEJcWasp6t08QM3SGLDoBv-X",
  encryptKey: "1324576890abcdef1324576890abcdef",
  encryptIV: "abcdef1324576890abcdef1324576890",
  firebase: {
    apiKey: "AIzaSyBaCDAzqpnCs7BNdoo4eoapYnhjqvdyHuA",
    authDomain: "app-quiz-2d0c4.firebaseapp.com",
    databaseURL: "https://app-quiz-2d0c4-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "app-quiz-2d0c4",
    storageBucket: "app-quiz-2d0c4.appspot.com",
    messagingSenderId: "897008508559",
    appId: "1:897008508559:web:d0d65aeaf0fc683e8c5afa",
    // measurementId: "G-PHLW24G1RN"
  }
  // firebase: {
  //   apiKey: "qwYUCYWLpdd3MQJpoJIUJoUWGu8GhFc8sYzJk6Ac",
  //   authDomain: "unictive---staging.firebaseapp.com",
  //   databaseURL: "https://mqbc-staging.asia-southeast1.firebasedatabase.app/",
  //   projectId: "unictive---staging",
  //   storageBucket: "unictive---staging.appspot.com",
  //   messagingSenderId: "1041899482744",
  //   appId: "1:1041899482744:web:8b875fac399cfc3f23478d",
  //   measurementId: "G-PHLW24G1RN"
  // }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
