import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { tap, map, take, switchMap } from "rxjs/operators";
import { BehaviorSubject, from } from "rxjs";
import { UserModel } from "../models/user.model";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { LanguageService } from "./language.service";
import firebase from 'firebase/app'
import "firebase/database";
import { NavController } from "@ionic/angular";
import { Preferences } from '@capacitor/preferences';
import { TokenModel } from "../models/token.model";
export const myFirebaseRedirect = firebase.initializeApp(
  environment.firebase,
  "redirectFirebase"
);
export const chatRoomDefault = "/multiple_login/";
declare var generateLiveChat: Function;
declare var generateLiveChat2: Function;

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private _user = new BehaviorSubject<UserModel>(new UserModel());
  private _token = new BehaviorSubject<TokenModel>(new TokenModel())
  private _fcm = new BehaviorSubject<any>(null);
  private myFirebaseListener:any;
  language:any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private languageService: LanguageService,
    private navCtrl: NavController
  ) {
    this.language = this.languageService.language();
  }


  //new


  getToken(){

    return this._token.value.getToken();

  }

  setTokenObserver(value:TokenModel) {
    this._token.next(value);
  }

  setUserObserver(value:UserModel) {
    this._user.next(value);

    // if(value.getMemberID() != 0){
    //   this.callForceRedirectFunction(this.getToken(), value)
    // }

  }

  getUserObserver() {
    return this._user;
  }

  getUserObserverValue() {
    return this._user.value;
  }

  //new

  get userIsAuthenticated() {
    return this._user.asObservable().pipe(
      map((user) => {
        if (user) {
          return ''
        } else {
          return false;
        }
      })
    );
  }

  get token() {
    return this._user.asObservable().pipe(
      map((user) => {
        if (user) {
          return '';
        } else {
          return null;
        }
      })
    );
  }

  get user() {
    var dataUser = this._user.value;

    if (dataUser) {
      localStorage['dataGlobal'] = btoa(JSON.stringify(dataUser));
      // generateLiveChat();
    } else {
      //generateLiveChat2();
    }
    return this._user.asObservable();
  }

  login(
    nip: string,
    passCode: string,
    token: string,
    isForce:any
  ) {
    let body = new FormData();
    body.append("nip", nip);
    body.append("password", passCode);
    body.append("token", token);

    if(isForce){
      body.append('force', "1");
    }

    return this.http.post<any>(`${environment.baseUrl}/api/auth/login`, body).pipe(
      map((resData) => {
        return resData;
      })
      // tap(this.setUserData.bind(this))
    );
  }


  getTokenOnly() {
    if (localStorage['dataGlobal']) {
      try {
        var getData = JSON.parse(atob(localStorage['dataGlobal']));
        return getData._token;
      } catch (e) {}
    }
  }

  callForceRedirectFunction(dataToken:any, user:UserModel) {
       if (dataToken) {
         let myLoginToken = dataToken;
        //  console.log(myLoginToken)
        //  console.log(user.getMemberID())
         this.myFirebaseListener = myFirebaseRedirect.database().ref(chatRoomDefault + user.getMemberID());
         this.myFirebaseListener.on('value', (snapshot:any) => {
           let myValue = snapshot.val();

             if(myValue){
               if (myLoginToken == myValue.access_token) {
                 ///   console.log('Token tidak sama');
                 if (this.myFirebaseListener) {
                   this.myFirebaseListener.off();
                 }

                 this.logoutDualLogin()
               } else {
                 //  console.log('Token Sama');
               }
             }
         });
       }
  }

  logout(type = 0) {
    if (this.myFirebaseListener) {
      this.myFirebaseListener.off();
    }
    this._user.next(new UserModel());
    this._token.next(new TokenModel())
    // Plugins.Storage.remove({ key: 'authData' });
    // Plugins.Storage.remove({ key: 'token2' });
    Preferences.clear();
    localStorage.removeItem("dataGlobal");
    localStorage.removeItem("authData");
    localStorage.clear();
    if (type == 0) {
      // this.router.navigateByUrl('/');
      this.navCtrl.navigateRoot("/home");
      //window.location.reload()
    } else {
      // this.router.navigateByUrl('/');
      // this.navCtrl.navigateRoot('/home')
      // window.location.href = environment.baseUrlShare;
    }
  }



  logoutNOREDIRECT(type = 0) {
    if (this.myFirebaseListener) {
      this.myFirebaseListener.off();
    }
    this._user.next(new UserModel());
    this._token.next(new TokenModel())
    // Plugins.Storage.remove({ key: 'authData' });
    // Plugins.Storage.remove({ key: 'token2' });
    Preferences.clear();
    localStorage.removeItem("dataGlobal");
    localStorage.removeItem("authData");
    localStorage.clear();

    this.router.navigateByUrl('', {replaceUrl:true})

  }

  logoutDualLogin(type = 0) {
    if (this.myFirebaseListener) {
      this.myFirebaseListener.off();
    }
    this._user.next(new UserModel());
    this._token.next(new TokenModel())
    // Plugins.Storage.remove({ key: 'authData' });
    // Plugins.Storage.remove({ key: 'token2' });
    Preferences.clear();
    localStorage.removeItem("dataGlobal");
    localStorage.removeItem("authData");
    localStorage.clear();

    this.router.navigateByUrl('/dual-login', {replaceUrl:true})

  }

  logout_V2() {
    //    return this.token.pipe(
    //      take(1),
    //      switchMap(token => {
    //        const httpOptions = {
    //          headers: new HttpHeaders({
    //            'Authorization': `Bearer ${token}`
    //          })
    //        };
    //        return this.http.get<any>(
    //          `${environment.baseUrl}/api/logout`, httpOptions
    //        );
    //      })
    //    );
  }

  get fcm() {
    return this._fcm.asObservable();
  }

  setFcmToken(token:any) {
    this._fcm.next(token);
  }

  checkCode(code:any) {
    let body = new FormData();
    body.append("code", code);
    return this.http
      .post<any>(`${environment.baseUrl}/api/member/checkcode`, body)
      .pipe(
        map((resData) => {
          return resData;
        })
        // tap(this.setUserData.bind(this))
      );
  }

}
